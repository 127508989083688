// 存储数据
export const setStorageItem = (key, value) => {
  if (typeof value === "object") {
    value = JSON.stringify(value)
  }
  sessionStorage.setItem(key, value)
}
// 获取数据
export const getStorageItem = (key) => {
  const data = sessionStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}
// 删除指定数据
export const delStorageItem = (key) => {
  sessionStorage.removeItem(key)
}
// 删除全部数据
export const removeAll = () => {
  sessionStorage.clear()
}