import Vue from "vue";
import Cookies from "js-cookie"
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录页面"
    },
    component: () => import(/* webpackChunkName: "Login" */ "../views/Login")
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "首页"
    },
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home")
  },
  {
    path: "*",
    name: "Home",
    meta: {
      title: "首页"
    },
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home")
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to, from, next) => {
  const LOGIN_PAGE_NAME = 'Login'
  const homeName = 'Home'
  const token = Cookies.get("token")
  if (!token && to.name !== LOGIN_PAGE_NAME && to.name !== 'FindPass') {
    // 未登录且要跳转的页面不是登录页
    next({
      name: LOGIN_PAGE_NAME // 跳转到登录页
    })
  } else if (!token && to.name === LOGIN_PAGE_NAME) {
    // 未登陆且要跳转的页面是登录页
    next() // 跳转
  } else if (token && to.name === LOGIN_PAGE_NAME) {
    // 已登录且要跳转的页面是登录页
    next({
      name: homeName // 跳转到homeName页
    })
  } else {
    next()
  }
})

export default router;
