import Vue from "vue";
import Vuex from "vuex";
import Address from "./modules/address";
import { setStorageItem, getStorageItem, delStorageItem } from '@/utils/storage'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shopInfo: getStorageItem('shopInfo') || null,
    lmReturnObj: getStorageItem('lmReturnObj') || null,
  },
  mutations: {
    setShopInfo (state, val) {
      state.shopInfo = val
      setStorageItem('shopInfo', val)
    },
    setLmReturnObj (state, val) {
      state.lmReturnObj = val
      setStorageItem('lmReturnObj', val)
    },
  },
  actions: {},
  modules: {
    Address,
  }
});
