export default {
  namespaced: true,
  state: {},
  mutations: {
    changeState(state, payload) {
      state.data = { ...state.data, ...payload };
    },
  },
  actions: {}
};
