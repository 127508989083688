import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
//reset css 文件
import "./assets/css/reset.css";
// 引入iconfont
import "./assets/svg/iconfont.css";
// 引入vant样式文件
import 'vant/lib/index.css';
// 导入使用到的vant组件
import './vant/index'
import { Lazyload } from "vant";
Vue.use(require("vue-wechat-title"));
Vue.use(Lazyload);
Vue.use(Lazyload, {
  lazyComponent: true
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
